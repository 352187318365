import { t } from '@zupr/i18n'
import React from 'react'

import LinkChangeQuery, {
    LinkChangeQueryProps,
} from '../router/link-change-query'

import Clear from '../../../svg/cross.svg'

interface RemoveKeysProps extends LinkChangeQueryProps {
    keys: string[]
}

export const RemoveKeys = ({ keys, ...props }: RemoveKeysProps) => (
    <LinkChangeQuery
        {...props}
        query={{
            ...keys.reduce((obj, key) => ({ ...obj, [key]: null }), {}),
            // sometimes turning off a filter we need to set it to false instead of null
        }}
    >
        {t('Filters wissen')}
    </LinkChangeQuery>
)

interface Props {
    filterKey?: string
    children: React.ReactNode
    href?: string
    removeFromValue?: string
    value?: string
    query?: Record<string, string | null>
}

const RemoveFilter = ({
    filterKey,
    children,
    removeFromValue,
    value,
    href,
    query = {},
}: Props) => {
    let newValue = null

    // if value contains multiple values
    if (value?.includes(',')) {
        newValue = `${value}`
            .split(',')
            .filter((val) => val !== removeFromValue)
    }

    return (
        <LinkChangeQuery
            className="btn-flex"
            href={href}
            query={
                filterKey
                    ? {
                          [filterKey]: newValue,
                      }
                    : query
            }
        >
            <span className="btn btn-micro btn-light">
                <Clear />
            </span>
            <span>{children}</span>
        </LinkChangeQuery>
    )
}

export default RemoveFilter
